import { ROOT_URL } from "config";

const getAgencies = async () => {
  try {
    const response = await fetch(ROOT_URL + `/program/agency`, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getAgencyPrograms = async ({ id }) => {
  try {
    const response = await fetch(
      ROOT_URL +
        `/program?` +
        new URLSearchParams({
          agency_id: id,
        }),
      {
        method: "GET",
      },
    );

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

export { getAgencies, getAgencyPrograms };
