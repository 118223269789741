import React, { useContext } from "react";
import { NewCitizenContext } from "../NewCitizenContext";
import { DownloadIdStepContainer } from "pages/Register/components/Register.styled";
import IDBack from "assets/id_back.jpg";
import { useNavigate } from "react-router-dom";

const IDCard = () => {
  const navigate = useNavigate();
  const { IDCard, downloadCitizenID, citizenIDCode } = useContext(NewCitizenContext);

  const handleAddSocialService = () => {
    navigate(`/citizen/${citizenIDCode}/redeem`);
  };

  return (
    <DownloadIdStepContainer>
      <div className="content-container">
        <div className="text-content">
          <h2 className="header-text">Maraming Salamat sa pag-Rehistro sa Bagong Pilipinas!</h2>
          <p className="help-text">
            Narito ang iyong ID. Click the button below to <span className="help-text-download">download.</span>
          </p>
        </div>
        <img src={IDBack} className="id" />
        <img src={IDCard} className="id" />
        <button onClick={downloadCitizenID} className="download-id-btn" disabled={!IDCard}>
          Download
        </button>
        <button onClick={handleAddSocialService} className="register-again-btn">
          Add Social Service
        </button>
      </div>
    </DownloadIdStepContainer>
  );
};

export default IDCard;
