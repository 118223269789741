const { ROOT_URL } = require("config");
const getCitizen = async (id) => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/${id}`, {
      method: "GET",
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error);
    } else {
      return await response.json();
    }
  } catch (Exception) {
    console.error(Exception.toString());
  }
};

const getCitizenByCardNumber = async (id) => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/card_number/${id.trim()}`, {
      method: "GET",
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error);
    } else {
      return await response.json();
    }
  } catch (Exception) {
    console.error(Exception);
  }
};

const getCitizensByLastNameAndBirthdate = async (
  last_name,
  birth_date,
  limit,
  offset
) => {
  const params = new URLSearchParams({
    last_name: last_name.trim(),
    birth_date,
    limit,
    offset,
  });

  try {
    const response = await fetch(ROOT_URL + `/citizen/?${params}`, {
      method: "GET",
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error);
    } else {
      return await response.json();
    }
  } catch (Exception) {
    console.error(Exception);
  }
};

const getHistory = async (id) => {
  try {
    const response = await fetch(
      ROOT_URL + `/citizen/${id}/social-service/history`,
      {
        method: "GET",
      }
    );

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const redeemSocialService = async (data) => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/redeem/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getProgramTypes = async () => {
  try {
    const response = await fetch(ROOT_URL + `/program/type/`, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getCode = async (code) => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/code/${code}/`, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

export {
  getCitizen,
  getHistory,
  redeemSocialService,
  getCitizenByCardNumber,
  getCitizensByLastNameAndBirthdate,
  getProgramTypes,
  getCode,
};
