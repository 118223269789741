import React from "react";
import {
  BPLogo,
  Background,
  Gradient,
  Layout,
  LeftSideContainer,
  MobileBackground,
  MobileGradient,
  RightSideContainer,
} from "./Layout";
import BP_LOGO from "assets/bagong_pilipinas_logo.png";
import BG from "assets/bg.png";
import GRADIENT from "assets/corner_gradient.png";
import MOBILE_BG from "assets/mobile_bg.png";
import MOBILE_GRADIENT from "assets/mobile_gradient.png";

const MainLayout = ({ children }) => {
  return (
    <Layout>
      <LeftSideContainer>
        <BPLogo src={BP_LOGO} />
        <Background src={BG} />
      </LeftSideContainer>

      <RightSideContainer>
        {children}
        <Gradient src={GRADIENT} />
      </RightSideContainer>

      <MobileGradient src={MOBILE_GRADIENT} />
      <MobileBackground src={MOBILE_BG} />
    </Layout>
  );
};

export default MainLayout;
