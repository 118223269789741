/* eslint-disable no-case-declarations */
import { createContext, useContext } from "react";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  userCode: null,
  login: () => null,
  logout: () => null,
  verify: () => null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE":
      return { ...state, isInitialized: true };

    case "LOGIN":
      const { userCode } = action.payload;
      return { ...state, isAuthenticated: true, isInitialized: true, userCode };

    case "LOGOUT":
      return { ...initialState, isInitialized: true };

    default:
      return state;
  }
};

const RegisterContext = createContext(initialState);
const useRegisterContext = () => useContext(RegisterContext);

export { initialState, reducer, useRegisterContext };
export default RegisterContext;
