import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button as ANTDButton } from "antd";

const RegisterButton = styled(ANTDButton)`
  border-radius: 8px;
  width: 100%;
  // margin: 5px 0px;

  &.ant-btn-primary {
    background-color: #ac141e;
    color: #ffffff;
    border-color: #ac141e;

    span {
      color: #ffffff;
      font-weight: bold;
    }

    &:hover,
    &:focus {
      background-color: #9a121b;
      border-color: #9a121b;
    }

    &:disabled {
      background-color: #b4b4b4;
      border-color: #b4b4b4;

      span {
        color: #000000;
        font-weight: bold;
      }
    }
  }

  &.ant-btn-default {
    background-color: #bcbcbc;
    border-color: #bcbcbc;

    span {
      color: #000000;
      font-weight: bold;
    }

    &:hover,
    &:focus {
      background-color: #ebebeb;
      border-color: #ebebeb;
    }
  }

  &.ant-btn-social-service {
    background-color: #ff6e03;
    border-color: #ff6e03;

    span {
      color: #ffffff;
      font-weight: bold;
    }

    &:hover,
    &:focus {
      background-color: #ff6900;
      border-color: #ff6900;
    }
  }
`;

const RegisterButtonContainer = styled.div`
  width: 80vw;

  @media only screen and (min-width: 768px) {
    width: 30%;
  }
`;

const Button = ({ text, onClick, type, disabled, style }) => {
  return (
    <RegisterButtonContainer>
      <RegisterButton type={type} onClick={onClick} disabled={disabled} style={style}>
        {text}
      </RegisterButton>
    </RegisterButtonContainer>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.any,
};

export default Button;
