import React, { useRef, useState } from "react";
import { Alert, Button, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { StyledElement as BackgroundContainer } from "pages/SocialService/components/reusables";
import { useZxing } from "react-zxing";
import styled from "styled-components";
import Title from "antd/lib/typography/Title";

const QrScanner = styled.video`
  width: 100%;
  height: calc(100% - 165px);
  object-fit: cover;
  position: absolute;
  top: 165px;
  z-index: -1;
`;

const Container = styled(BackgroundContainer)`
  height: 100vh;
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 100%;
`;

const Header = styled(Title)`
  color: white !important;
  margin: 0 !important;
`;

const TopOverlay = styled.div`
  position: absolute;
  background-color: rgba(41, 51, 146, 0.68);
  display: flex;

  align-items: center;
  justify-content: center;

  height: 100px;
  width: 100vw;
`;

const BottomOverlay = styled.div`
  position: absolute;
  bottom: 0;
  background-color: rgba(41, 51, 146, 0.68);
  display: flex;
  flex-direction: column;

  height: 180px;
  padding-top: 25px;
  padding-left: 10%;
  padding-right: 10%;
  width: 100vw;
`;

const LeftOverlay = styled.div`
  position: absolute;
  background-color: rgba(41, 51, 146, 0.68);
  display: flex;
  top: 265px;

  left: 0;

  align-items: center;
  justify-content: center;

  justify-content: center;
  height: calc(100% - 195px - 250px);
  width: 10%;
`;

const RightOverlay = styled.div`
  position: absolute;
  background-color: rgba(41, 51, 146, 0.68);
  top: 265px;

  right: 0;

  align-items: center;
  justify-content: center;

  height: calc(100% - 195px - 250px);
  width: 10%;
`;

const SearchButton = styled(Button)`
  z-index: 2;
  margin-top: 15px;
  border-radius: 10px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #dd4542;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.07);
`;

const IdSearchInput = styled(Input)`
  margin-top: 10px;
`;

const Overlay = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  return (
    <div style={{ zIndex: 5 }}>
      <TopOverlay>
        <Header level={3}>Scan QR Code</Header>
      </TopOverlay>
      <LeftOverlay />
      <RightOverlay />
      <BottomOverlay>
        <Header level={4}>Couldn’t find Citizen?</Header>
        <Header level={5}>Enter ID number instead.</Header>
        <IdSearchInput ref={inputRef} placeholder="Enter ID Number" />
        <SearchButton
          onClick={() => {
            navigate(`/citizen/${inputRef.current.input.value.trim()}`);
          }}>
          Search Citizen
        </SearchButton>
      </BottomOverlay>
    </div>
  );
};
const QrScanPage = () => {
  const [error, setError] = useState(null);
  const { ref } = useZxing({
    onError: error => {
      setError({
        message: "Error",
        description: error.message,
      });
    },
    paused: !!error,
    timeBetweenDecodingAttempts: 1000,
    constraints: {
      video: {
        facingMode: "environment",
      },
    },
    onDecodeResult(result) {
      if (result) {
        if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(result.getText())) {
          navigate(`/citizen/${result.getText()}`);
        } else {
          ``;
          setError({
            message: "Error",
            description: "Invalid QR Code",
          });
        }
      }
    },
  });

  const navigate = useNavigate();

  return (
    <Container>
      <Overlay />
      <QrScanner ref={ref} />

      {error ? (
        <>
          <Alert message={error.message} description={error.description} type="error" showIcon />
          <Button
            onClick={() => {
              setError(null);
            }}
            type="primary"
            block>
            Retry
          </Button>
        </>
      ) : null}
    </Container>
  );
};

export default QrScanPage;
