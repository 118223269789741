import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Select } from "antd";
import { getAgencies, getAgencyPrograms } from "services/meta";
import { useNavigate } from "react-router-dom";
import { getProgramTypes, redeemSocialService } from "services/citizen";
import { useSocialServiceContext } from "../Context";
import TextArea from "antd/lib/input/TextArea";
import StyledButton from "components/button/Button";
import styled from "styled-components";
import Swal from "sweetalert2";

const RedeemButton = styled(StyledButton)`
  background: #293392;
`;

const RedeemForm = () => {
  const navigate = useNavigate();
  const [agency, setAgency] = useState([]);
  const [agencyPrograms, setAgencyPrograms] = useState([]);
  const [programTypes, setProgramTypes] = useState([]);
  const { citizen } = useSocialServiceContext();

  const [form] = Form.useForm();

  const [selectedAgency, setSelectedAgency] = useState();
  const [selectedProgram, setSelectedProgram] = useState();
  const [selectedProgramType, setSelectedProgramType] = useState();
  console.log(citizen);

  const onFinish = ({ proposed_amount, agency, program, ...rest }) => {
    redeemSocialService({
      citizen: citizen.id,
      proposed_amount: proposed_amount || 0,
      agency,
      program,
      overrides: {
        agency: rest["overrides.agency"],
        program: rest["overrides.program"],
        details: rest["overrides.details"],
      },
    })
      .then(res => {
        if (res.error) {
          Swal.fire({
            title: "Oops!",
            text: res.error,
            allowOutsideClick: true,
            showCancelButton: true,
            cancelButtonText: "Exit",
            showConfirmButton: false,
            width: "80%",
          });
          return;
        }
        navigate(`/citizen/${citizen.id}`);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    getAgencies().then(agency => setAgency(agency?.results.map(({ name, id }) => ({ value: id, label: name }))));
  }, []);

  useEffect(() => {
    if (selectedAgency) {
      getAgencyPrograms({ id: selectedAgency }).then(agencyPrograms =>
        setAgencyPrograms(agencyPrograms?.results.map(({ name, id }) => ({ value: id, label: name }))),
      );
    }
  }, [selectedAgency]);

  return (
    <>
      <div style={{ fontSize: "13px", lineHeight: 0.5, marginBottom: "0.5rem" }}>
        <p>Please select the services you would like to avail.</p>
        <p>Select not more than 4 services. </p>
      </div>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          minWidth: "90%",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Form.Item
          name="agency"
          wrapperCol={{
            span: 24,
          }}
          rules={[
            {
              required: true,
              message: "Agency is required",
            },
          ]}>
          <Select
            onChange={value => {
              setSelectedAgency(value);
              form.setFieldsValue({ program: undefined });
              setSelectedProgram(undefined);
            }}
            options={agency}
            placeholder={"Select Agency"}
          />
        </Form.Item>

        {selectedAgency === 33 ? (
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
            name="overrides.agency"
            rules={[
              {
                required: true,
                message: "Agency Name is required",
              },
            ]}>
            <Input style={{ width: "100%" }} placeholder={"Enter Agency Name"} />
          </Form.Item>
        ) : null}

        <Form.Item
          wrapperCol={{
            span: 24,
          }}
          name="program"
          dependencies={["agency"]}
          rules={[
            {
              required: true,
              message: "Program is required",
            },
          ]}>
          <Select
            onChange={value => {
              setSelectedProgram(value);
            }}
            options={agencyPrograms}
            placeholder={"Select Program"}
            disabled={!selectedAgency}
          />
        </Form.Item>

        {selectedProgram === 110 ? (
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
            name="overrides.program"
            dependencies={["agency", "overrides.agency"]}
            rules={[
              {
                required: true,
                message: "Program Name is required",
              },
            ]}>
            <Input style={{ width: "100%" }} placeholder={"Enter Social Service"} />
          </Form.Item>
        ) : null}

        {selectedProgramType === 1 ? (
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
            name="proposed_amount"
            dependencies={["agency", "program"]}
            rules={[
              {
                required: true,
                message: "Proposed Amount is required",
              },
            ]}>
            <InputNumber
              prefix="₱"
              style={{ width: "100%" }}
              disabled={!selectedProgram}
              placeholder={"Input Proposed Amount"}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            />
          </Form.Item>
        ) : null}

        {selectedProgramType === 2 ? (
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
            name="overrides.details"
            dependencies={["agency", "program"]}
            rules={[
              {
                required: true,
                message: "Details is required",
              },
            ]}>
            <TextArea placeholder="Enter Details" autoSize />
          </Form.Item>
        ) : null}

        <Form.Item>
          <RedeemButton type="primary" htmlType="submit">
            Redeem
          </RedeemButton>
        </Form.Item>
      </Form>
    </>
  );
};

export default RedeemForm;
