import React, { useCallback, useEffect, useReducer, useRef } from "react";
import Context, { initialState, reducer } from "./Context";
import { getCode } from "services/citizen";

const userLocalKey = "userCode";
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isVerified = useRef(false);

  const login = useCallback((userCode) => {
    localStorage.setItem(userLocalKey, userCode);
    dispatch({
      type: "LOGIN",
      payload: {
        userCode,
      },
    });

    return userCode;
  }, []);

  const logout = useCallback(() => {
    try {
      localStorage.removeItem(userLocalKey);
      dispatch({ type: "LOGOUT" });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const verify = useCallback(async () => {
    try {
      const userData = localStorage.getItem(userLocalKey);
      if (userData) {
        const isValid = await getCode(userData);

        if (!isValid) {
          throw new Error("Unauthenticated!");
        }

        login(userData);
      }
    } catch (error) {
      dispatch({ type: "INITIALIZE" });
      console.warn(error);
      localStorage.removeItem(userLocalKey);
    }
  }, []);

  useEffect(() => {
    if (!isVerified.current) {
      verify();
      isVerified.current = true;
    }
  }, []);

  return (
    <Context.Provider value={{ ...state, login, logout, verify }}>
      {children}
    </Context.Provider>
  );
};

export default Provider;
