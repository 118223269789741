import { Button } from "antd";
import { styled } from "styled-components";

const StyledButton = styled(Button)`
  border-radius: 11px;
  background: #293392;
  width: 100%;
  span {
    color: #ffff;
  }

  &:disabled {
    border-radius: 11px;
    background: rgba(41, 51, 146, 0.8);
    span {
      color: #ffffff;
    }

    &:hover,
    &:focus {
      background-color: rgba(41, 51, 146, 0.8);
      border-color: rgba(41, 51, 146, 0.8);
    }
  }
`;

export default StyledButton;
