import MainLayout from "layouts/MainLayout/MainLayout";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CitizenProfile from "../components/CitizenProfile";
import { useSocialServiceContext } from "../Context";
import { getCitizen, getCitizenByCardNumber } from "services/citizen";
import { styled } from "styled-components";
import { Alert, Col, Row, Spin } from "antd";
import StyledButton from "components/button/Button";
import History from "../components/History";
import { MobileContainer, StyledElement } from "../components/reusables";
import Swal from "sweetalert2";

const BackButton = styled(StyledButton)`
  height: 43px;
  border-radius: 11px;
  background: rgba(217, 217, 217, 0.21);
  min-width: 108px;
  span {
    color: initial;
  }
  @media only screen and (min-width: 768px) {
    background: white;
    span {
      color: initial;
    }
  }
`;

const RedeemButton = styled(StyledButton)`
  height: 43px;
  @media only screen and (min-width: 768px) {
    background: transparent !important;
  }
`;

const CitizenDetail = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { setCitizen, citizen, history } = useSocialServiceContext();

  const maxAvailSocialService = () => {
    Swal.fire({
      title: "Oops!",
      text: "The citizen already has 4 or more redemption",
      allowOutsideClick: true,
      showCancelButton: true,
      cancelButtonText: "Exit",
      showConfirmButton: false,
      width: "80%",
    });
  };

  useEffect(() => {
    if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id)) {
      getCitizen(id)
        .then(citizen => {
          setIsLoading(false);
          setCitizen(citizen);
        })
        .catch(error => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      getCitizenByCardNumber(id)
        .then(citizen => {
          setCitizen(citizen);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, []);

  if (!id) {
    throw Error("No ID provided");
  }

  return (
    <StyledElement>
      <MobileContainer>
        <MainLayout>
          {isLoading && <Spin />}
          {!citizen && !isLoading ? (
            <Row gap={15}>
              <Col span={24}>
                <Alert message={"Error"} showIcon type="error" description={"Having trouble loading citizen data"} />
              </Col>
              <Col span={24} justify={"center"}>
                <Link to={-1}>
                  <BackButton>Back</BackButton>
                </Link>
              </Col>
            </Row>
          ) : null}
          {citizen ? (
            <>
              <CitizenProfile {...citizen} />
              <Row gutter={15} justify="center">
                <Col>
                  <Link to={history?.length < 4 && `/citizen/${id}/redeem`}>
                    <RedeemButton onClick={() => history?.length === 4 && maxAvailSocialService()}>
                      Add Social Service
                    </RedeemButton>
                  </Link>
                </Col>
                <Col>
                  <Link to={-1}>
                    <BackButton>Back</BackButton>
                  </Link>
                </Col>
              </Row>
              {citizen ? <History /> : null}
            </>
          ) : null}
        </MainLayout>
      </MobileContainer>
    </StyledElement>
  );
};

export default CitizenDetail;
